@charset "UTF-8";
/* Cerillion Theme - Additional Vars */
:root {
  --clipPathPercentage: 85%; /* percentage offset from left edge */
  --angledHeaderOffset: 10px; /* offset of background colour from bottom of clip path */
  --takeover-banner: 95vh;
  --takeover-banner-margin: 5vh;
  --headerTransparency: .9;
}

/* ubuntu-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("fonts/ubuntu-v20-latin-300.woff2") format("woff2"), url("fonts/ubuntu-v20-latin-300.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* ubuntu-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/ubuntu-v20-latin-regular.woff2") format("woff2"), url("fonts/ubuntu-v20-latin-regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* ubuntu-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/ubuntu-v20-latin-500.woff2") format("woff2"), url("fonts/ubuntu-v20-latin-500.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* ubuntu-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/ubuntu-v20-latin-700.woff2") format("woff2"), url("fonts/ubuntu-v20-latin-700.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
:root {
  --logo-typography_font-name: "Ubuntu", sans-serif;
  --body-typography_font-name: "Ubuntu", sans-serif;
  --h1-typography_font-name: "Ubuntu", sans-serif;
  --h2-typography_font-name: "Ubuntu", sans-serif;
  --h3-typography_font-name: "Ubuntu", sans-serif;
  --h4-typography_font-name: "Ubuntu", sans-serif;
  --h5-typography_font-name: "Ubuntu", sans-serif;
  --h6-typography_font-name: "Ubuntu", sans-serif;
  --p-heading-typography_font-name: "Ubuntu", sans-serif;
  --p-heading_small-typography_font-name: "Ubuntu", sans-serif;
  --p-heading_large-typography_font-name: "Ubuntu", sans-serif;
  --p-intro-typography_font-name: "Ubuntu", sans-serif;
  --p-intro_small-typography_font-name: "Ubuntu", sans-serif;
  --p-intro_large-typography_font-name: "Ubuntu", sans-serif;
  --secondary-heading-typography_font-name: "Ubuntu", sans-serif;
  --secondary-heading_small-typography_font-name: "Ubuntu", sans-serif;
  --secondary-heading_large-typography_font-name: "Ubuntu", sans-serif;
  --blockquote-typography_font-name: "Ubuntu", sans-serif;
  --blockquote_small-typography_font-name: "Ubuntu", sans-serif;
  --blockquote_large-typography_font-name: "Ubuntu", sans-serif;
  --btn-typography_font-name: "Ubuntu", sans-serif;
  --btn_small-typography_font-name: "Ubuntu", sans-serif;
  --btn_large-typography_font-name: "Ubuntu", sans-serif;
  --main-navigation-typography_font-name: "Ubuntu", sans-serif;
  --secondary-navigation-typography_font-name: "Ubuntu", sans-serif;
  --main-navigation_dropdowns-typography_font-name: "Ubuntu", sans-serif;
  --sub-navigation-typography_font-name: "Ubuntu", sans-serif;
  --footer-navigation-typography_font-name: "Ubuntu", sans-serif;
  --breadcrumb-navigation-typography_font-name: "Ubuntu", sans-serif;
  --anchor-navigation-typography_font-name: "Ubuntu", sans-serif;
  --acc-tab-typography_font-name: "Ubuntu", sans-serif;
  --body-typography_font-weight: 300;
  --h1-typography_font-weight: 700;
  --h2-typography_font-weight: 700;
  --h3-typography_font-weight: 700;
  --h4-typography_font-weight: 700;
  --h5-typography_font-weight: 700;
  --h6-typography_font-weight: 700;
  --p-heading-typography_font-weight: 700;
  --p-heading_small-typography_font-weight: 500;
  --p-heading_large-typography_font-weight: 500;
  --p-intro-typography_font-weight: 300;
  --p-intro_small-typography_font-weight: 300;
  --p-intro_large-typography_font-weight: 300;
  --secondary-heading-typography_font-weight: 500;
  --secondary-heading_small-typography_font-weight: 300;
  --secondary-heading_large-typography_font-weight: 300;
  --blockquote-typography_font-weight: 700;
  --blockquote_small-typography_font-weight: 700;
  --blockquote_large-typography_font-weight: 700;
  --btn-typography_font-weight: 700;
  --btn_small-typography_font-weight: 700;
  --btn_large-typography_font-weight: 700;
  --main-navigation-typography_font-weight: 500;
  --secondary-navigation-typography_font-weight: 300;
  --main-navigation_dropdowns-typography_font-weight: 300;
  --sub-navigation-typography_font-weight: 300;
  --footer-navigation-typography_font-weight: 300;
  --breadcrumb-navigation-typography_font-weight: 300;
  --anchor-navigation-typography_font-weight: 300;
  --acc-tab-typography_font-weight: 300;
  --logo-typography_font-weight: 300;
}

.highlight__light-blue {
  color: #22CAE6 !important;
}
.highlight__dark-blue {
  color: #192259;
}

h4, h5, h6, p.heading {
  margin: calc(var(--base-spacing) / 3) 0 var(--base-spacing) 0;
}

/* h1 */
h1, .h1 {
  font-family: var(--h1-typography_font-name);
  font-weight: var(--h1-typography_font-weight);
  font-style: var(--h1-typography_font-style);
  line-height: var(--h1-typography_font-line-height);
  letter-spacing: var(--h1-typography_font-letter-spacing);
  text-transform: var(--h1-typography_font-transform);
  font-size: var(--h1-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h1, .h1 {
    font-size: var(--h1-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h1, .h1 {
    font-size: var(--h1-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h1, .h1 {
    font-size: var(--h1-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: var(--h1-typography_font-size_xl);
  }
}
/* h2 */
h2, .h2 {
  font-family: var(--h2-typography_font-name);
  font-weight: var(--h2-typography_font-weight);
  font-style: var(--h2-typography_font-style);
  line-height: var(--h2-typography_font-line-height);
  letter-spacing: var(--h2-typography_font-letter-spacing);
  text-transform: var(--h2-typography_font-transform);
  font-size: var(--h2-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h2, .h2 {
    font-size: var(--h2-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h2, .h2 {
    font-size: var(--h2-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h2, .h2 {
    font-size: var(--h2-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: var(--h2-typography_font-size_xl);
  }
}
/* h3 */
h3, .h3 {
  font-family: var(--h3-typography_font-name);
  font-weight: var(--h3-typography_font-weight);
  font-style: var(--h3-typography_font-style);
  line-height: var(--h3-typography_font-line-height);
  letter-spacing: var(--h3-typography_font-letter-spacing);
  text-transform: var(--h3-typography_font-transform);
  font-size: var(--h3-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h3, .h3 {
    font-size: var(--h3-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h3, .h3 {
    font-size: var(--h3-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h3, .h3 {
    font-size: var(--h3-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: var(--h3-typography_font-size_xl);
  }
}
/* h4 */
h4, .h4 {
  font-family: var(--h4-typography_font-name);
  font-weight: var(--h4-typography_font-weight);
  font-style: var(--h4-typography_font-style);
  line-height: var(--h4-typography_font-line-height);
  letter-spacing: var(--h4-typography_font-letter-spacing);
  text-transform: var(--h4-typography_font-transform);
  font-size: var(--h4-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h4, .h4 {
    font-size: var(--h4-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h4, .h4 {
    font-size: var(--h4-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h4, .h4 {
    font-size: var(--h4-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: var(--h4-typography_font-size_xl);
  }
}
/* h5 */
h5, .h5 {
  font-family: var(--h5-typography_font-name);
  font-weight: var(--h5-typography_font-weight);
  font-style: var(--h5-typography_font-style);
  line-height: var(--h5-typography_font-line-height);
  letter-spacing: var(--h5-typography_font-letter-spacing);
  text-transform: var(--h5-typography_font-transform);
  font-size: var(--h5-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h5, .h5 {
    font-size: var(--h5-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h5, .h5 {
    font-size: var(--h5-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h5, .h5 {
    font-size: var(--h5-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: var(--h5-typography_font-size_xl);
  }
}
/* h6 */
h6, .h6 {
  font-family: var(--h6-typography_font-name);
  font-weight: var(--h6-typography_font-weight);
  font-style: var(--h6-typography_font-style);
  line-height: var(--h6-typography_font-line-height);
  letter-spacing: var(--h6-typography_font-letter-spacing);
  text-transform: var(--h6-typography_font-transform);
  font-size: var(--h6-typography_font-size);
}

@media (min-width: 576px) and (max-width: 767px) {
  h6, .h6 {
    font-size: var(--h6-typography_font-size_sm);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h6, .h6 {
    font-size: var(--h6-typography_font-size_md);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  h6, .h6 {
    font-size: var(--h6-typography_font-size_lg);
  }
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: var(--h6-typography_font-size_xl);
  }
}
h1:not(.heading),
.h1:not(.heading),
h2:not(.heading),
.h2:not(.heading),
h3:not(.heading),
.h3:not(.heading),
h4:not(.heading),
.h4:not(.heading),
h5:not(.heading),
.h5:not(.heading),
h6:not(.heading),
.h6:not(.heading) {
  color: rgba(var(--base-heading), 1);
}

.btn.btn-secondary {
  background-color: #22CAE6;
  transition: all 0.25s;
}
.btn.btn-secondary:hover {
  background-color: #192259;
}
.btn.btn-icon {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.btn.btn-icon i {
  padding-left: 1rem;
  padding-right: 0.5rem;
  transition: all 0.25s;
}
.btn.btn-icon:hover i {
  transform: translateX(5px);
}
.btn:has(i.icon):not(.btn-search) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.btn:has(i.icon):not(.btn-search) > .icon {
  margin-left: 1rem;
}

:root {
  --link-padding: 0.75rem 0;
  --menu-section-padding: 1rem 2rem;
  --menu-section-padding-v: 1rem;
  --menu-section-padding-h: 2rem;
  --border-color: #efefef;
  --shadow-color: 204, 204, 204;
  --link-color: #000;
  --link-color-hover: #005572;
  --bufferWidth: 40px;
}

header#site-header:hover {
  --headerTransparency: 1;
}

header#site-header .main-navigation-list .main-navigation-list--item .main-navigation-list--link {
  position: relative;
}
header#site-header .main-navigation-list .main-navigation-list--item .main-navigation-list--link.null-link {
  cursor: default;
}
header#site-header .main-navigation-list .main-navigation-list--item .main-navigation-list--link.active:before {
  height: 5px !important;
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child {
  /*&:hover {
      .main-navigation-panel {
          // display: block;
          opacity: 1;
          transform: translateY(0);
          pointer-events: all;
      }
  }*/
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child .main-navigation-panel.in {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child.over {
  position: relative;
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child.over > span:after {
  transform: rotateZ(180deg);
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child.over:before {
  position: absolute;
  left: calc(var(--bufferWidth) * -1);
  content: " ";
  height: 100%;
  width: var(--bufferWidth);
  top: 0;
  z-index: 10000;
}
header#site-header .main-navigation-list .main-navigation-list--item.has-child.over:after {
  position: absolute;
  right: calc(var(--bufferWidth) * -1);
  content: " ";
  height: 100%;
  width: var(--bufferWidth);
  top: 0;
  z-index: 10000;
}
header#site-header .main-navigation-list .main-navigation-panel {
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 60vh;
  border-bottom: 1px solid #999;
  z-index: 1000;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  background-color: #FFF;
  background-image: url(../images/cerillion/menu-bg.webp);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50%;
  transition: all 0.25s;
  transition-delay: 0.25s;
  opacity: 0;
  transform: translateY(-5%);
  pointer-events: none;
}
header#site-header .main-navigation-list .main-navigation-panel > .container {
  position: relative;
  height: 100%;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  overflow: hidden;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left {
  overflow-y: auto;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right {
  overflow-y: hidden;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing {
  display: none;
  column-gap: 30px;
  height: 100%;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing.active {
  display: flex;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section {
  height: 100%;
  flex-basis: 100%;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section {
  flex-basis: 50%;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary {
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1.5;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  align-items: flex-start;
  border-top: 1px solid #D6D9DA;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary.cerillioncasestudy .menu-icon {
  margin-right: var(--desktop-cs-menu-icon-margin-right) !important;
  border-radius: var(--desktop-cs-menu-icon-border-radius) !important;
  width: var(--desktop-cs-menu-icon-max-width) !important;
  height: var(--desktop-cs-menu-icon-max-height) !important;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary.cerillioncasestudy .menu-icon.menu-icon--with-background {
  padding: var(--desktop-cs-menu-icon-padding) !important;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary.cerillioncasestudy .menu-icon img {
  max-height: var(--desktop-cs-menu-icon-max-height-img) !important;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary > span {
  flex-grow: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary .menu-icon {
  margin-right: var(--desktop-menu-icon-margin-right);
  border-radius: var(--desktop-menu-icon-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--desktop-menu-icon-max-width);
  height: var(--desktop-menu-icon-max-height);
  flex: 1 0 auto;
  flex-grow: 0;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary .menu-icon.menu-icon--with-background {
  padding: var(--desktop-menu-icon-padding);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-list span.page-summary .menu-icon img {
  flex-grow: 0;
  width: auto;
  max-height: var(--desktop-menu-icon-max-height-img);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section .mnp-section-header {
  display: block;
  border-bottom: 1px solid var(--border-color);
  color: #666;
  font-weight: 300;
  line-height: 2;
  margin-bottom: 1rem;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section .mnp-link-item {
  padding-top: 0;
  padding-bottom: 1.5rem;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section .mnp-link-item.current {
  color: var(--link-color-hover);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing .mnp-section-list.two-column {
  display: grid;
  width: 100%;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-column-gap: 30px;
  /*column-count: 2;
  column-gap: 30px;     */
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing .mnp-section-list.two-column a {
  break-inside: avoid;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing .mnp-section-list.two-column .grouped-section {
  break-inside: avoid;
  background-color: #f3f3f3;
  padding: 1rem;
  margin-bottom: 1rem;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing .mnp-section-list.two-column .grouped-section-header {
  color: #999;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  padding-bottom: 5px;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left {
  box-shadow: -5px 0 15px rgba(var(--shadow-color), 0.8);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item.has-subpages .opener {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item.has-subpages .opener::after {
  font: normal normal normal 16px/1 "Ionicons";
  content: "\f3d0";
  transform: translateX(-3px) rotateZ(-90deg);
  transition: 0.25s;
  opacity: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item.has-subpages .opener:hover:after {
  transform: translateX(0);
  color: #22CAE6;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item.has-subpages.active .opener:after {
  color: #22CAE6;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item.current {
  color: var(--link-color-hover);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item.null-link:hover {
  color: #22CAE6;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item.active {
  color: #22CAE6;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item span {
  font-size: 16px;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item .mnp-link-item.item-link {
  flex-grow: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left .nav-item:hover::after {
  transform: translateX(0);
  opacity: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--left {
  border-right: 1px solid #efefef;
  padding: var(--menu-section-padding);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section {
  padding: var(--menu-section-padding);
  overflow-y: auto;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.next-to-featured-section {
  padding-left: 0;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section {
  background-color: rgba(240, 241, 242, 0.5);
  box-shadow: inset 5px 0 10px rgba(var(--shadow-color), 0.8);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-grid .mnp-grid-item--right .mnp--listing__section.focus-section .mnp-section-header {
  border-bottom: 1px solid #D6D9DA;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item {
  display: block;
  padding: var(--link-padding);
  color: var(--link-color);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item.current {
  color: var(--link-color-hover);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item:hover {
  color: var(--link-color-hover);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item span.page-summary {
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1.5;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  align-items: flex-start;
  border-top: 1px solid #D6D9DA;
  break-inside: avoid;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item span.page-summary > span {
  flex-grow: 1;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item span.page-summary .menu-icon {
  margin-right: var(--desktop-menu-icon-margin-right);
  border-radius: var(--desktop-menu-icon-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--desktop-menu-icon-max-width);
  height: var(--desktop-menu-icon-max-height);
  flex: 1 0 auto;
  flex-grow: 0;
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item span.page-summary .menu-icon.menu-icon--with-background {
  padding: var(--desktop-menu-icon-padding);
}
header#site-header .main-navigation-list .main-navigation-panel .mnp-link-item span.page-summary .menu-icon img {
  flex-grow: 0;
  width: auto;
  max-height: var(--desktop-menu-icon-max-height-img);
}

:root {
  --search-button-size: 38px;
}

.cer-mobile-menu .cer-mobile-menu--header-logo {
  width: 100px;
}
.cer-mobile-menu--header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--header-bg-color1), 1);
  padding: 0 10px 0 20px;
  position: relative;
  z-index: 1;
}
.cer-mobile-menu--header button.close-menu {
  position: relative;
  border: 0;
  background: none;
  padding: 0;
}
.cer-mobile-menu--header button.close-menu span {
  padding: 0;
  text-indent: -9999px;
  border: 0;
  background: none;
  width: 40px;
  height: 60px;
  top: 0;
  right: 0;
  position: relative;
}
.cer-mobile-menu--header button.close-menu span::before {
  position: absolute;
  background-color: rgba(var(--main-navigation-link), 1);
  content: " ";
  display: block;
  width: calc(var(--expand-nav-width) / 2);
  height: 2px;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  top: 50%;
  left: 50%;
}
.cer-mobile-menu--header button.close-menu span::after {
  position: absolute;
  background-color: rgba(var(--main-navigation-link), 1);
  content: " ";
  display: block;
  width: calc(var(--expand-nav-width) / 2);
  height: 2px;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 50%;
  left: 50%;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header > span {
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 5px;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header:after {
  border-top: 1px solid #000;
  opacity: 1;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header::before {
  content: "";
  display: none;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search {
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search::after {
  content: "";
  display: none;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search .cer-site-search form {
  width: 100%;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search .cer-site-search form fieldset {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search .cer-site-search form fieldset > .form-group {
  flex-grow: 1;
  margin: 0;
  padding-right: 1rem;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search .cer-site-search form fieldset button.btn-search {
  background-color: rgba(var(--header-bg-color1), 1);
  color: #FFF;
  padding: 0;
  width: var(--search-button-size);
  height: var(--search-button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
}
.cer-mobile-menu .cer-mobile-menu--inactive-header.mobile-site-search .cer-site-search form fieldset button.btn-search:hover {
  background-color: #192259;
}
.cer-mobile-menu.mm {
  background-color: #FFF;
}
.cer-mobile-menu.mm:after {
  z-index: 2;
  background-color: rgba(var(--header-bg-color1), 1);
  opacity: 1;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-weight: 500;
}
.cer-mobile-menu.mm:before {
  border-top: 2px solid #FFF;
  border-left: 2px solid #FFF;
  z-index: 3;
  display: flex;
  align-items: center;
  top: 30px;
  opacity: 1;
}
.cer-mobile-menu.mm.mm--main:after {
  opacity: 0;
  pointer-events: none;
}

nav.cer-mobile-menu.mm {
  background-image: url(../images/cerillion/menu-bg.webp);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 150%;
}
nav.cer-mobile-menu.mm ul li a .mobile-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
nav.cer-mobile-menu.mm ul li a .mobile-link span {
  padding: 0;
  flex-grow: 1;
}
nav.cer-mobile-menu.mm ul li a .mobile-link .menu-icon {
  padding: var(--mobile-menu-icon-padding);
  margin-right: var(--mobile-menu-icon-margin-right);
  border-radius: var(--mobile-menu-icon-border-radius);
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.cer-mobile-menu.mm ul li a .mobile-link .menu-icon img {
  flex-grow: 0;
  max-width: var(--mobile-menu-icon-max-width);
  height: 30px;
  width: auto;
}

/* START -- Angled header - requires custom class adding to header */
@media all and (min-width: 992px) {
  .component.usn_cmp_banner .item.angled-header {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 var(--clipPathPercentage));
    margin-bottom: var(--takeover-banner-margin);
  }
  .component.usn_cmp_banner .item.angled-header {
    background-color: #1D8DBD;
  }
  .component.usn_cmp_banner .item.angled-header .image {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 var(--clipPathPercentage));
    top: auto;
    bottom: var(--angledHeaderOffset);
    min-height: var(--angledHeaderMinHeight);
  }
  .component.usn_cmp_banner .item.angled-header, .component.usn_cmp_banner .item.angled-header .info.col {
    min-height: var(--angledHeaderMinHeight);
  }
}
@media all and (min-width: 1351px) {
  .component.usn_cmp_banner .item.angled-header .flex-row-reverse .info.col {
    padding-right: 70px;
  }
  .component.usn_cmp_banner .item.angled-header .info.col {
    padding-left: 70px;
  }
}
/* END -- Angled header - requires custom class adding to header */
@media all and (min-width: 992px) {
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height {
    height: 55vh !important;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    margin-bottom: 5vh;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height {
    background-color: #1D8DBD;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .image {
    height: 55vh !important;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .image {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    top: auto;
    bottom: 10px;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .row {
    height: 55vh !important;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .info {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .info > .cer-breadcrumbs {
    margin-bottom: 3rem;
  }
  body.transparent-header-lg:not(.browser-ios) .component.usn_cmp_banner .item.item_takeover-banner.angled-header.half-height .info h1 {
    font-weight: 500;
  }
}
.case-study-carousel {
  position: relative;
}
.case-study-carousel .component-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0;
}
.case-study-carousel .component-inner {
  position: relative;
  z-index: 2;
}
.case-study-carousel:before {
  content: " ";
  background-color: rgba(29, 141, 189, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.case-study-carousel .slides .slick-dots {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 2.75rem;
  justify-content: center;
  margin: 0;
}
.case-study-carousel .slides .slick-dots li {
  margin: 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.case-study-carousel .slides .slick-dots li button {
  width: 10px;
  height: 10px;
}
.case-study-carousel .slides .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
}
.case-study-carousel .slides .item .inner {
  transition: width 0.25s;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #FFF;
  padding: 2rem 5rem 2rem 3rem;
  border-radius: 0.5rem;
}
.case-study-carousel .slides .item .inner--left {
  float: left;
}
.case-study-carousel .slides .item .inner--right {
  float: right;
  background-color: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}
@media all and (max-width: 991px) {
  .case-study-carousel .slides .item .inner--right {
    display: none;
  }
}
.case-study-carousel .slides .item .inner .content-heading {
  position: absolute;
  right: 2rem;
  top: 1.75rem;
  text-align: right;
  font-weight: 500;
  color: #192259;
  font-size: 0.85rem;
}
.case-study-carousel .slides .item .inner > a {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.case-study-carousel .slides .item .inner .info .secondary-heading {
  font-weight: 500;
  margin-top: 6px !important;
}
@media all and (max-width: 585px) {
  .case-study-carousel .slides .item .inner .image-container {
    margin-top: 2rem;
  }
}
.case-study-carousel .slides .item .inner .image {
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
}
.case-study-carousel .slides .item .inner .image img {
  height: 100%;
  width: auto;
}
.case-study-carousel .slides .item .inner .carousel-quote {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding-left: 60px;
  position: relative;
}
.case-study-carousel .slides .item .inner .carousel-quote .quote-mark {
  background-image: url(../images/cerillion/icons/quote.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 50px;
  margin-bottom: 1rem;
}
.case-study-carousel .slides .item .inner .carousel-quote .text {
  color: #FFF;
  font-weight: normal;
  width: 100%;
  font-size: 1.75rem;
  line-height: 2.25rem;
}
.case-study-carousel .slides .item .inner .carousel-quote .source {
  color: #FFF;
  width: 100%;
  margin-top: 30px;
}
.case-study-carousel .slides .item .inner .carousel-quote .source:before {
  border-top: 3px solid #FFF;
  width: 30px;
  height: 30px;
  content: " ";
  display: block;
}
.case-study-carousel .slides .item .inner .carousel-quote .source--name {
  display: block;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 500;
}
.case-study-carousel .slides .item .inner .carousel-quote .source--company {
  display: block;
}
@media all and (min-width: 1200px) {
  .case-study-carousel .slides .item .inner {
    width: 60%;
  }
}
@media all and (min-width: 1200px) {
  .case-study-carousel .slides .slick-dots {
    right: calc(40% + 1.75rem);
  }
}

.content.component .component-introduction .info:has(.link) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .content.component .component-introduction .info:has(.link) {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media all and (min-width: 1201px) {
  .content.component .component-introduction .info:has(.link) .heading {
    flex-grow: 1;
    padding-right: 2rem;
  }
}
@media all and (max-width: 1200px) {
  .content.component .component-introduction .info:has(.link) .heading {
    max-width: 100%;
  }
}
.content.component .component-introduction .link {
  margin-top: 0;
}
@media all and (max-width: 1200px) {
  .content.component .component-introduction .link {
    margin-top: 1rem;
  }
}

@media all and (min-width: 992px) {
  .content.component.cer-full-width-carousel .component-inner .container-fluid.hybrid {
    padding: 0;
  }
  .content.component.cer-full-width-carousel .component-inner .container-fluid.hybrid > .row .col-md-3 {
    max-width: calc((100% - 1350px) / 2 + 15px);
    flex-basis: calc((100% - 1350px) / 2 + 15px);
  }
  .content.component.cer-full-width-carousel .component-inner .container-fluid.hybrid > .row .col-md-9 {
    max-width: calc(100% - calc((100% - 1350px) / 2 + 15px));
    flex-basis: calc(100% - calc((100% - 1350px) / 2 + 15px));
  }
  .content.component.cer-full-width-carousel .component-inner .row.slides .item {
    padding-left: 0;
    padding-right: 0;
  }
  .content.component.cer-full-width-carousel .component-inner .row.slides .item .inner {
    padding-right: 30px;
  }
  .content.component.cer-full-width-carousel .component-inner .row.slides .item .inner .image {
    box-shadow: rgba(0, 0, 0, 0.35) 10px 20px 15px;
  }
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-dots .slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-dots .slick-dots li {
  list-style: none;
  margin: 0 15px 10px 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-dots .slick-dots li button {
  background-color: #D3D7D9;
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-dots .slick-dots li.slick-active button {
  background-color: #90979A;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-arrows {
  display: flex;
  align-items: center;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-arrows button {
  width: 31px;
  height: 31px;
  margin-right: 15px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-arrows button i {
  color: #FFF;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-arrows button.slick-arrow {
  background-color: #192259;
}
.content.component.cer-full-width-carousel .component-inner .cer-fwc-carousel-nav .cer-fwc-arrows button.slick-arrow.slick-disabled {
  background-color: #D3D7D9;
}
.content.component.cer-full-width-carousel .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev {
  left: 0;
  bottom: 0;
  top: auto;
  transform: none;
}
.content.component.cer-full-width-carousel .row.slides.slick-slider:not(.listing_no-spacing) .slick-next {
  right: 0;
  bottom: 0;
  top: auto;
  transform: none;
}

#compare-table.responsive-compare-table {
  background-color: #FFF;
}

#responsivePricingTable article {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome and Opera */
}

#responsivePricingTable ul {
  display: flex;
  top: 0px;
  z-index: 10;
  padding-bottom: 14px;
}

#responsivePricingTable li {
  list-style: none;
  flex: 1;
}

#responsivePricingTable li:last-child {
  border-right: 1px solid #DDD;
}

#responsivePricingTable button {
  width: 100%;
  border: 1px solid #DDD;
  border-right: 0;
  border-top: 0;
  padding: 6px 10px;
  background: #FFF;
  font-size: 14px;
  font-weight: normal;
  color: #666;
  text-transform: uppercase;
}

#responsivePricingTable li.active button {
  background: #fafafa;
  color: #333;
  font-weight: bold;
}

#responsivePricingTable table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

#responsivePricingTable th {
  background: #fafafa;
  display: none;
}

#responsivePricingTable td,
#responsivePricingTable th {
  height: 53px;
}

#responsivePricingTable td,
#responsivePricingTable th {
  border: 1px solid #DDD;
  padding: 10px;
  empty-cells: show;
}

#responsivePricingTable td,
#responsivePricingTable th {
  text-align: left;
}

#responsivePricingTable thead tr:first-child th:not(:first-child),
#responsivePricingTable thead td:not(:first-child) {
  background: none;
}

#responsivePricingTable thead {
  display: none;
}

#responsivePricingTable td + td,
#responsivePricingTable th + th {
  text-align: center;
  display: none;
}

#responsivePricingTable thead th, #responsivePricingTable li {
  position: relative;
}

#responsivePricingTable thead th.default:before {
  content: "Most popular";
  position: absolute;
  text-align: center;
  left: -1px;
  right: 0px;
  top: -35px;
  font-size: 12px;
  letter-spacing: 0.05em;
  background-color: #CCC;
  color: #FFF;
  text-transform: uppercase;
  padding: 6px 0 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#responsivePricingTable ul li.default:before {
  content: "Most popular";
  position: absolute;
  text-align: center;
  left: 0;
  right: 0px;
  top: -35px;
  font-size: 12px;
  letter-spacing: 0.05em;
  background-color: #CCC;
  color: #FFF;
  text-transform: uppercase;
  padding: 6px 0 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#responsivePricingTable td.default, #responsivePricingTable thead th.default {
  display: table-cell;
  background-color: #FAFAFA;
}

#responsivePricingTable tbody td.default, #responsivePricingTable tfoot td.default {
  background-color: #FAFAFA;
}

#responsivePricingTable th.bg-essential, #responsivePricingTable li.bg-essential {
  border-top: 10px solid #398FC2;
}

#responsivePricingTable th.bg-standard, #responsivePricingTable li.bg-standard {
  border-top: 10px solid #A98FD8;
}

#responsivePricingTable th.bg-metered, #responsivePricingTable li.bg-metered {
  border-top: 10px solid #FB5A52;
}

#responsivePricingTable th.bg-enterprise, #responsivePricingTable li.bg-enterprise {
  border-top: 10px solid #37CACF;
}

#responsivePricingTable thead th.bg-essential.default:before,
#responsivePricingTable li.bg-essential.default:before {
  background-color: #398FC2;
}

#responsivePricingTable thead th.bg-standard.default:before,
#responsivePricingTable li.bg-standard.default:before {
  background-color: #A98FD8;
}

#responsivePricingTable thead th.bg-metered.default:before,
#responsivePricingTable li.bg-metered.default:before {
  background-color: #FB5A52;
}

#responsivePricingTable thead th.bg-enterprise.default:before,
#responsivePricingTable li.bg-enterprise.default:before {
  background-color: #37CACF;
}

#responsivePricingTable tbody td.bg-essential, #responsivePricingTable tbody td i:before {
  font-size: 20px;
}

#responsivePricingTable tbody td.bg-essential, #responsivePricingTable tbody td.bg-essential i {
  color: #398FC2;
}

#responsivePricingTable tbody td.bg-standard, #responsivePricingTable tbody td.bg-standard i {
  color: #A98FD8;
}

#responsivePricingTable tbody td.bg-metered, #responsivePricingTable tbody td.bg-metered i {
  color: #FB5A52;
}

#responsivePricingTable tbody td.bg-enterprise, #responsivePricingTable tbody td.bg-enterprise i {
  color: #37CACF;
}

#responsivePricingTable .bg-essential .btn.btn-default {
  background-color: #398FC2;
}

#responsivePricingTable .bg-standard .btn.btn-default {
  background-color: #A98FD8;
}

#responsivePricingTable .bg-metered .btn.btn-default {
  background-color: #FB5A52;
}

#responsivePricingTable .bg-enterprise .btn.btn-default {
  background-color: #37CACF;
}

#responsivePricingTable .sep {
  background: #F5F5F5;
  font-weight: bold;
}

#responsivePricingTable .txt-l {
  font-size: 28px;
  font-weight: bold;
}

#responsivePricingTable .txt-top {
  position: relative;
  top: -9px;
  left: -2px;
}

#responsivePricingTable .tick {
  font-size: 18px;
  color: #2CA01C;
}

#responsivePricingTable .hide {
  border: 0;
  background: none !important;
}

#responsivePricingTable .hide.expand-all {
  vertical-align: bottom;
  padding-left: 0 !important;
}

#responsivePricingTable .hide.expand-all a {
  margin-bottom: 10px;
}

#responsivePricingTable tbody tr.price-mobile td {
  text-transform: uppercase;
  font-weight: bold;
}

#responsivePricingTable tbody tr.price-mobile td:first-child {
  font-weight: normal;
}

@media (min-width: 886px) {
  #responsivePricingTable thead {
    display: table-header-group;
  }
  #responsivePricingTable tbody tr.price-mobile {
    display: none;
  }
  #responsivePricingTable ul {
    display: none;
  }
  #responsivePricingTable td,
  #responsivePricingTable th {
    display: table-cell !important;
  }
  #responsivePricingTable td,
  #responsivePricingTable th {
    width: 330px;
  }
  #responsivePricingTable td + td,
  #responsivePricingTable th + th {
    width: auto;
  }
  #compare-table.responsive-compare-table .currency-wrapper {
    float: right;
  }
}
#responsivePricingTable i.no {
  display: none;
}

#responsivePricingTable tr.feature-section {
  cursor: pointer;
}

#responsivePricingTable tbody tr td:first-child {
  background-color: #FFF;
  color: #666;
}

#responsivePricingTable tr.feature-section td {
  background-color: #F6F6F6;
}

#responsivePricingTable tr.feature-section td:first-child {
  font-weight: bold;
  color: #333;
}

#responsivePricingTable tr.feature-section td:first-child {
  background-color: #FAFAFA;
}

#responsivePricingTable tr.feature-section:hover td {
  background-color: #e6e6e6;
}

#responsivePricingTable tr.feature-section.active td {
  background-color: #e6e6e6;
}

#responsivePricingTable th .pricing-header-wrapper {
  padding: 15px 0;
}

#responsivePricingTable th h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 6px 0;
  padding: 0;
}

#responsivePricingTable th .text-upper {
  color: #7b808c;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
}

#responsivePricingTable th .price, #responsivePricingTable th .poa {
  color: #333;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 0;
  line-height: 38px;
}

#responsivePricingTable th .includes {
  font-size: 13px;
  font-weight: normal;
}

#responsivePricingTable th .includes a {
  color: #7b808c;
  display: inline-block;
  margin: 10px 0;
  text-decoration: none;
  border-bottom: 1px dotted #7b808c;
  outline: none;
}

#responsivePricingTable th .includes.redirect a {
  border-bottom: 1px solid #7b808c;
}

#responsivePricingTable th .includes.redirect a:hover {
  color: #333;
}

.tooltip.tooltip-white.in {
  opacity: 1;
}

.tooltip.tooltip-white .tooltip-inner {
  color: #333;
  background-color: #fafafa;
  font-size: 14px;
  font-family: "HelveticaNeueW01-45Ligh", Arial, sans-serif;
  opacity: 1;
  padding: 10px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

.tooltip.tooltip-white .tooltip-arrow {
  border-top-color: #FFF;
}

#compare-table.responsive-compare-table .btn-group-currency {
  margin-bottom: 0;
}

.responsive-compare-table .currency-wrapper .btn-group .btn.btn-primary {
  border: 1px solid #ccc;
  box-shadow: none;
  background-color: #fafafa;
  color: #999;
  font-weight: bold;
  font-size: 18px;
}

#compare-table.responsive-compare-table .btn-exclude:focus,
#compare-table.responsive-compare-table .btn-exclude:active,
#compare-table.responsive-compare-table .btn-currency:focus,
#compare-table.responsive-compare-table .btn-currency:active {
  box-shadow: none !important;
  background: #FFF !important;
  color: #333 !important;
}

.responsive-compare-table .currency-wrapper .btn-group .btn.btn-primary.selected {
  background: #FFF;
  color: #333;
}

.responsive-pricing__key {
  float: right;
}

.responsive-pricing__key ul {
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: left;
}

.responsive-pricing__key ul li {
  display: inline-block;
  padding: 6px 10px;
  color: #7b808c;
  font-size: 15px;
}

.responsive-pricing__key ul li i.fa-check, .responsive-pricing__key ul li i.icon.usn_ion-md-checkmark {
  color: #666;
  margin-right: 10px;
}

.responsive-compare-table__upper {
  margin-bottom: 60px;
}

@media (max-width: 885px) {
  .responsive-pricing__key {
    float: none;
    width: 60%;
    margin: 20px auto 0 auto;
  }
  .responsive-pricing__key ul {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    float: left;
  }
  .responsive-pricing__key ul li {
    display: block;
    float: left;
    width: 50%;
    text-align: center;
  }
  #responsivePricingTable button {
    font-size: 11px;
    padding: 10px 0;
  }
  #compare-table.responsive-compare-table {
    padding: 30px 0;
  }
  #compare-table.responsive-compare-table tfoot tr td:first-child {
    display: table-cell !important;
  }
}
@media (max-width: 430px) {
  #responsivePricingTable ul li.default::before {
    top: -50px;
  }
  .responsive-pricing__key {
    width: 100%;
    margin: 20px auto 0 auto;
  }
  .responsive-pricing__key ul {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    float: left;
  }
}
#responsivePricingTable tr.feature-section td:first-child:before {
  content: "\f3d0";
  font-family: "Ionicons";
  font-weight: normal;
  margin-right: 10px;
  color: #666;
  display: inline-block;
}

#responsivePricingTable tr.feature-section.active td:first-child:before {
  content: "\f3d0";
  transform: rotateZ(180deg);
}

.module-container-title {
  display: none;
}

@media screen and (min-width: 992px) {
  section.content.cer-accordion {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
section.content.cer-accordion .image.background-image.component-background-image {
  background-position: center right !important;
  background-size: 40vw auto !important;
}
@media screen and (max-width: 991px) {
  section.content.cer-accordion .image.background-image.component-background-image {
    display: none !important;
  }
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .tab .nav-link {
  border: 0;
  font-weight: 500;
  color: #192259;
  font-size: 1.25rem;
  border-bottom: 1px solid #D6D9DA;
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .tab .nav-link:hover {
  background-color: transparent;
  color: #22CAE6;
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .tab .nav-link:after {
  font-family: "Ubuntu", sans-serif !important;
  content: "+";
  font-weight: 500;
  font-size: 1.25rem;
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .tab .nav-link:not(.collapsed):after {
  content: "-";
  font-family: "Ubuntu", sans-serif !important;
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .info {
  border: 0;
}
section.content.cer-accordion .usn_cmp_accordiontab.cer-accordion .repeatable.accordion.tab-basic .info .text {
  padding-left: 0;
  padding-right: 0;
}

.inner-item-row:not(.flex-row-reverse) .cel--focal-image__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 57px;
  padding-left: 0;
}
.inner-item-row:not(.flex-row-reverse) .cel--focal-image__wrapper img {
  max-width: 600px;
}

.inner-item-row.flex-row-reverse .cel--focal-image__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  justify-content: flex-start;
}
.inner-item-row.flex-row-reverse .cel--focal-image__wrapper img {
  max-width: 600px;
}

@media (min-width: 1351px) {
  .component.usn_cmp_banner .item.angled-header .inner-item-row:not(.flex-row-reverse) .info.col {
    padding-left: 20px;
    padding-right: 0;
  }
  .component.usn_cmp_banner .item.angled-header .inner-item-row .info.col {
    padding-left: 0px;
    padding-right: 57px;
  }
}
:root {
  --subHeaderHeight: 420px;
  --subHeaderHeightMobile: 300px;
  --subHeaderInnerHeightMobile: calc(var(--subHeaderHeightMobile) - var(--pc-spacing_xl));
  --subHeaderInnerHeight: calc(var(--subHeaderHeight) - var(--pc-spacing_xl));
  --subHeaderMobileOffset: 60px;
}

.content.usn_cmp_subpageheader {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cer_subpage-header {
  position: relative;
  padding: 0;
}
.cer_subpage-header .cer-breadcrumbs {
  margin: 0 0 2rem 0;
}
.cer_subpage-header .cer-breadcrumbs--link {
  color: #FFF !important;
}
.cer_subpage-header .cer_subpage-header--headings p.secondary-heading {
  margin-top: 1rem !important;
}
.cer_subpage-header .cer_subpage-header--headings.has-product-icon .heading-with-icon {
  display: flex;
  align-items: flex-start;
}
.cer_subpage-header .cer_subpage-header--headings.has-product-icon .heading-with-icon .heading-icon-wrapper {
  width: var(--product-subheader-iconsize);
  height: var(--product-subheader-iconsize);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--product-subheader-padding);
  margin-right: var(--product-subheader-margin-right);
  border-radius: var(--product-subheader-icon-border-radius);
  margin-top: 5px;
}
.cer_subpage-header .cer_subpage-header--headings.has-product-icon .heading-with-icon .heading-icon-wrapper .heading-icon {
  width: 100%;
  height: auto;
}
.cer_subpage-header .cer_subpage-header__upper-container {
  height: var(--subHeaderInnerHeight);
}
@media all and (max-width: 991px) {
  .cer_subpage-header .cer_subpage-header__upper-container {
    height: var(--subHeaderHeightMobile);
  }
}
.cer_subpage-header .cer_subpage-header__upper {
  height: calc(var(--subHeaderInnerHeight) - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 991px) {
  .cer_subpage-header .cer_subpage-header__upper {
    height: calc(var(--subHeaderInnerHeightMobile) - 60px);
  }
}
.cer_subpage-header .subpage-header-bg {
  background-image: url(../images/cerillion/ring-bg-light.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  min-height: 40vh;
  width: 50%;
  z-index: -1;
}
.cer_subpage-header .angled-header {
  height: var(--subHeaderHeight);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 var(--clipPathPercentage));
  background-color: #0090C0;
}
@media all and (max-width: 991px) {
  .cer_subpage-header .angled-header {
    height: var(--subHeaderHeightMobile);
  }
}
.cer_subpage-header .angled-header.keyline:after {
  content: " ";
  clip-path: polygon(0 0, 100% 65px, 100% 100%, 0 25px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 75px;
}
.cer_subpage-header .angled-header .image {
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 var(--clipPathPercentage));
  top: auto;
  bottom: var(--angledHeaderOffset);
}
.cer_subpage-header .angled-header .image.background-image {
  background-position: right center;
}
.cer_subpage-header .header-focus-image {
  margin-top: 60px;
}

.cer-breadcrumbs {
  list-style: none;
  margin: 2rem 0 0 0;
  padding: 0;
}
.cer-breadcrumbs li {
  display: inline-block;
  font-size: var(--breadcrumb-navigation-typography_font-size);
  position: relative;
  padding-right: 1rem;
  color: #FFF;
}
.cer-breadcrumbs li:not(:first-child) {
  padding-left: 1rem;
}
.cer-breadcrumbs li:after {
  content: ">";
  position: absolute;
  font-weight: normal;
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
}
.cer-breadcrumbs li.current {
  font-weight: 500;
}
.cer-breadcrumbs li.current:after {
  content: "";
}
.cer-breadcrumbs li a {
  color: #FFF;
  transition: color 0.25s ease-in-out;
}
.cer-breadcrumbs li a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: underline;
}

#site-footer .footer-links .text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#site-footer .footer-links .text > .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media all and (max-width: 991px) {
  #site-footer .footer-links .text.footer-text > .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
#site-footer {
  padding-bottom: calc(var(--pc-spacing_xl) / 1.5) !important;
}
#site-footer .footer-heading {
  font-size: 1rem;
}
#site-footer .footer-heading .footer-text {
  margin-top: 0;
}
#site-footer .footer-logo--wrapper__mobile {
  display: none;
  max-width: 100%;
}
#site-footer .footer-logo--wrapper__mobile img {
  width: 100%;
  max-width: 150px;
}
@media all and (max-width: 991px) {
  #site-footer .footer-logo--wrapper__mobile {
    display: block;
  }
}
#site-footer .footer-logo--wrapper__desktop {
  display: none;
  max-width: 150px;
}
@media all and (min-width: 1401px) {
  #site-footer .footer-logo--wrapper__desktop {
    max-width: 350px;
  }
}
@media all and (min-width: 992px) {
  #site-footer .footer-logo--wrapper__desktop {
    display: block;
  }
}
#site-footer .footer-logo--wrapper__desktop img {
  width: 100%;
  max-width: 150px;
}
#site-footer nav.footer-navigation {
  padding-top: var(--base-spacing) !important;
}
@media all and (min-width: 992px) {
  #site-footer .footer-item {
    margin-bottom: var(--base-spacing) !important;
  }
}
@media all and (min-width: 992px) {
  #site-footer .footer-lower-nav li {
    position: relative;
    padding-right: 1rem;
  }
  #site-footer .footer-lower-nav li:after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid #FFF;
  }
  #site-footer .footer-lower-nav li:last-child {
    padding-right: 0;
  }
  #site-footer .footer-lower-nav li:last-child:after {
    content: "";
    border-left: none;
  }
}
#site-footer .cer-footer-logo {
  width: auto !important;
}
@media all and (max-width: 991px) {
  #site-footer .cer-footer-logo {
    margin-bottom: 2rem;
  }
}
#site-footer .social ul li {
  margin: 0 10px 0 0;
}
#site-footer .social ul li a {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
}

.row:not(.slides):not(.listing_window-mosaic) .item {
  /*&.item_has-bg {
      .meta {
          min-height: 120px;
      }
  }*/
}
.row:not(.slides):not(.listing_window-mosaic) .item .inner {
  display: flex;
  flex-direction: column;
}
.row:not(.slides):not(.listing_window-mosaic) .item .inner--info-link {
  flex-grow: 1;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-item {
  flex: 0 0 50%;
  max-width: 50%;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-item .image {
  max-height: 450px;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-item .inner {
  background-color: #0090C0;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-item .inner .info p.heading {
  color: #FFF !important;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-item .inner .meta * {
  color: #FFF !important;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-post .inner {
  background-color: #0090C0;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-post .inner .info p.heading {
  color: #FFF !important;
}
.row:not(.slides):not(.listing_window-mosaic) .item.featured-post .inner .meta * {
  color: #FFF !important;
}
.row:not(.slides):not(.listing_window-mosaic).has-popular-post .item {
  margin-bottom: var(--base-spacing) !important;
}

.popular-posts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 35%;
  max-width: 35%;
}

/*@media all and (min-width: 1200px) {

}*/
.row:not(.slides):not(.listing_window-mosaic) .popular-posts {
  width: 100%;
}
.row:not(.slides):not(.listing_window-mosaic) .popular-posts .item:not(.items-1):not(.items-2):nth-last-child(2), .row:not(.slides):not(.listing_window-mosaic) .popular-posts .item:not(.items-1):not(.items-2):nth-last-child(1) {
  margin-bottom: var(--base-spacing) !important;
}
.row:not(.slides):not(.listing_window-mosaic) .popular-posts .popular-posts--heading {
  margin-top: 0;
  display: block;
  font-weight: 700;
  color: rgba(var(--c1-heading), 1);
  padding-bottom: 1.5rem;
  font-size: 24px;
}

.case-study--overview {
  margin-top: 60px;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #D6D9DA;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--base-spacing);
}
.case-study--overview .image {
  border: 0;
  background-color: #FFF;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.case-study--overview .image img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 0;
}
.case-study--overview .inner {
  background-color: #F0F1F2;
  padding: var(--base-spacing);
}
.case-study--overview .inner__header {
  border-bottom: 1px solid #797979;
  margin-bottom: var(--base-spacing);
}
.case-study--overview .inner__header h5 {
  color: #797979;
  font-size: var(--body-typography_font-size_xl);
}
.case-study--overview .inner__header h4 {
  font-weight: 700;
}
.case-study--overview .inner__body .inner-item {
  font-weight: 500;
}
.case-study--overview .inner__body .inner-item-header {
  display: block;
  color: rgba(var(--base-heading), 1);
}
.case-study--overview .inner__body .inner-item a {
  color: rgba(var(--base-link), 1);
  display: block;
}
.case-study--overview .inner__body .inner-item:last-child {
  margin-bottom: 0;
}

.case-study--related {
  margin-bottom: var(--base-spacing);
}
.case-study--related h4 {
  font-weight: 700;
  margin-bottom: var(--base-half-spacing);
}
.case-study--related .inner {
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #192259;
  background-image: url(../images/cerillion/related-circle.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--base-half-spacing) var(--base-spacing) var(--base-spacing) var(--base-spacing);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.case-study--related .inner__header {
  color: #FFF;
}
.case-study--related .inner__header h4 {
  color: #FFF;
}
.case-study--related .inner__header h4 a {
  color: #FFF;
}
.case-study--related .inner__header p {
  font-weight: 500;
}
@media all and (min-width: 992px) {
  .case-study--related .inner__header p {
    max-width: 80%;
  }
}
.case-study--related .inner__footer {
  margin-top: var(--base-half-spacing);
}
.case-study--related .inner__footer a, .case-study--related .inner__footer a:visited {
  color: #FFF;
  font-weight: 500;
}

.cer_case-study-content .base-bg {
  background: transparent;
}
.cer_case-study-content .component-inner > .container {
  padding-left: 0;
  padding-right: 0;
}

.info .results-box {
  padding: var(--base-spacing);
  background: #1D8DBD;
  background: linear-gradient(135deg, #1794C5 0%, #2675A8 100%);
  border-radius: 0.5rem;
}
.info .results-box h1, .info .results-box h2, .info .results-box h3, .info .results-box h4, .info .results-box h5, .info .results-box p, .info .results-box blockquote, .info .results-box a, .info .results-box li {
  color: #FFF;
}
.info .results-box p:last-child {
  margin-bottom: 0;
}
.info .results-box .results-inner {
  white-space: break-spaces;
  color: #FFF;
}
.info .results-box .results-inner a:link, .info .results-box .results-inner a:visited {
  text-decoration: underline !important;
}
.info .results-box .results-inner a:link:hover, .info .results-box .results-inner a:visited:hover {
  color: #22CAE6;
}
.info .results-box.results-box--module .results-inner {
  white-space: normal;
}

.visit-link {
  margin: var(--base-spacing) 0;
}

.info .text.cs-rollover-text {
  font-weight: 600;
  line-height: 1.5;
}

.sharing-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #F0F1F2;
  padding-top: calc(var(--base-half-spacing) / 2);
  margin-bottom: calc(var(--base-half-spacing) * 2);
}
.sharing-bar > a {
  color: #192259;
  font-weight: 700;
  font-size: 14px;
}
.sharing-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.sharing-bar ul li {
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
  color: #22CAE6;
}
.sharing-bar ul li > a, .sharing-bar ul li i {
  font-size: 16px;
  color: #192259;
}

.page-has-no-banner .cta-links .btn.c1-btn-text, .page-has-no-banner .cta-links .btn-text_c1-btn-text .btn {
  background: rgba(var(--c1-btn-bg-hover-color1), 1);
}

.quote-wrapper {
  background-color: #F0F1F2;
  padding: var(--base-spacing);
  margin-bottom: var(--base-spacing);
  border-radius: 0.5rem;
}
.quote-wrapper .quote {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 85px;
  padding-bottom: calc(var(--base-spacing) * 1.5);
  white-space: break-spaces;
}
.quote-wrapper .quote:before {
  content: " ";
  background-image: url(../images/cerillion/icons/quote.svg);
  background-repeat: no-repeat;
  width: 60px;
  height: 65px;
  transform: none;
  top: 0;
  left: 0;
}
.quote-wrapper .quote-source {
  font-weight: 700;
  color: #22CAE6;
  font-size: var(--h4-typography_font-size_xl);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.quote-wrapper .quote-source span {
  color: #192259;
  font-weight: 500;
  font-size: var(--body-typography_font-size_xl);
}
.quote-wrapper .quote-source.has-photo .quote-source-photo {
  display: block;
}
.quote-wrapper .quote-source .quote-source-photo {
  display: none;
  margin-right: 1rem;
}
.quote-wrapper .quote-source .quote-source-photo img {
  max-width: var(--inline-quote-img-max-width);
}

.testimonials-split .quote-wrapper {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
.testimonials-split .item .inner .info .heading {
  display: none !important;
}

.org-info .adr p.p-org.org {
  font-weight: 500;
  border-bottom: 1px solid #D6D9DA;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 30px;
}
.org-info .adr p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.org-info .adr p i {
  margin-right: 10px;
}
.org-info .adr p a {
  font-weight: 500;
}

.secondary-navigation {
  display: none;
  position: fixed;
  height: 30px;
  background: rgba(29, 141, 189, 0.75);
  z-index: 1002;
  transition: top 0.25s;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.secondary-navigation nav.secondary ul {
  justify-content: flex-end;
}
.secondary-navigation nav.secondary ul li {
  position: relative;
}
.secondary-navigation nav.secondary ul li:after {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  content: " ";
  height: 7.5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.secondary-navigation nav.secondary ul li:last-child:after {
  content: "";
  border: none;
}
.secondary-navigation nav.secondary ul li span a {
  color: #FFF;
  line-height: 30px !important;
}
.secondary-navigation nav.secondary ul li span a:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}

@media all and (min-width: 992px) {
  .secondary-navigation {
    display: block;
  }
  .secondary-navigation {
    top: -30px;
  }
  html.menu-at-top .secondary-navigation, html.reached-top .secondary-navigation {
    top: 0;
  }
}
@media all and (max-width: 767px) {
  .shareprice-widget__wrapper {
    display: none;
  }
}
@media all and (min-width: 768px) {
  .shareprice-widget__wrapper {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .shareprice-widget__wrapper .shareprice-widget {
    border: 1px solid #FFF;
    padding: var(--base-spacing) 0;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    max-width: 300px;
  }
}
.listing-filter {
  padding: var(--base-spacing);
  background-color: rgba(var(--c1-bg-color1), 1);
  margin-bottom: var(--base-spacing);
}
.listing-filter select {
  appearance: auto;
  border-color: #D6D9DA;
  border-radius: 0.25rem;
}
.listing-filter__slim {
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.listing-filter__slim--inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listing-filter__slim--inner input[type=submit] {
  margin-left: 1rem;
}
.listing-filter__slim--inner select {
  min-width: 300px;
}
@media all and (max-width: 991px) {
  .listing-filter__slim--inner {
    width: 100%;
  }
  .listing-filter__slim--inner select {
    min-width: auto;
    flex-grow: 1;
  }
  .listing-filter__slim--inner input[type=submit] {
    max-width: 25%;
  }
}

.item.item_casestudy .inner {
  position: relative;
}
.item.item_casestudy .inner a {
  display: block;
}
.item.item_casestudy .inner:hover .info {
  z-index: 2;
  opacity: 1;
}
.item.item_casestudy .inner:hover a .info:before {
  border: 10px solid rgba(255, 255, 255, 0.5);
}
.item.item_casestudy .inner:hover .image-container {
  opacity: 0.5;
}
.item.item_casestudy .inner .image-container {
  position: relative;
  z-index: 1;
  border: 1px solid #D6D9DA;
  transition: opacity 0.25s;
}
.item.item_casestudy .inner .info {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  background-color: rgba(29, 141, 189, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.25s;
}
.item.item_casestudy .inner .info:before {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0);
  transition: all 0.25s;
}
.item.item_casestudy .inner .info .heading, .item.item_casestudy .inner .info .secondary-heading, .item.item_casestudy .inner .info .text {
  color: #FFF !important;
}
.item.item_casestudy .inner .info .secondary-heading {
  font-weight: 700;
}

nav.sub.blog-archive .accordion {
  margin-top: 1rem;
}
nav.sub.blog-archive .accordion .card {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #D6D9DA;
}
nav.sub.blog-archive .accordion .card:last-child {
  border-bottom: 0;
}
nav.sub.blog-archive .accordion .card .card-header {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
nav.sub.blog-archive .accordion .card .card-header .link-wrapper {
  flex-grow: 1;
}
nav.sub.blog-archive .accordion .card .card-header .button-wrapper {
  position: relative;
  width: 40px;
}
nav.sub.blog-archive .accordion .card .card-header a {
  font-weight: 500;
  display: block;
  transition: all 0.25s;
  padding-left: 0;
}
nav.sub.blog-archive .accordion .card .card-header a:hover {
  padding-left: 3px;
  color: #192259 !important;
}
nav.sub.blog-archive .accordion .card .card-header button {
  border: 0;
  background: 0;
  position: relative;
  width: 100%;
  height: 20px;
  display: block;
}
nav.sub.blog-archive .accordion .card .card-header button:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateZ(0);
  font-family: "Ionicons";
  content: "\f3d0";
  opacity: 1;
  transition: all 0.25s;
}
nav.sub.blog-archive .accordion .card .card-header button.collapsed:after {
  transform: rotateZ(180deg);
}
nav.sub.blog-archive .accordion .card .card-body {
  padding: 0;
}
nav.sub.blog-archive .accordion .card .card-body ul li {
  padding: 8px 10px 8px 0;
}
nav.sub.blog-archive .accordion .card .card-body ul li a {
  transition: 0.25s;
  padding-left: 0;
}
nav.sub.blog-archive .accordion .card .card-body ul li a:hover {
  padding-left: 3px;
  color: #192259 !important;
}

nav.sub.blog-tags ul li span:after {
  content: "";
  display: none;
}
nav.sub.blog-tags .blog-tags-more {
  background: none;
  border: 0;
  padding: 0;
  font-size: 14px;
  color: rgba(var(--base-link), 1);
  display: block;
  width: 100%;
  border-top: 1px solid rgba(var(--base-link), 1);
  padding-top: 10px;
  margin-top: 10px;
  font-weight: bold;
  text-align: left;
}
nav.sub.blog-tags .blog-tags-more i {
  font-size: 14px;
}

/*nav.sub {
    ul.blog-category-navigation {
        li {
            &.active {
                span {
                    &:after {
                    }
                }
            }

            span {
                display: block;
                &:after {
                    left: auto;
                }

                a {
                    &:before {
                        display: none;
                    }
                }
            }

            a {
                padding-left: 0;
            }
        }
    }
}
*/
.image.blog-post-detail-image {
  margin-bottom: 1.5rem;
}

@media all and (min-width: 992px) {
  .image.blog-post-detail-image {
    float: right;
    max-width: 400px;
    margin-left: 2rem;
  }
}
.events-list__upcoming {
  margin-bottom: 2rem;
}
.events-list__upcoming h4 {
  margin-bottom: 1rem;
}
.events-list__previous {
  margin-bottom: 2rem;
}
.events-list__previous h4 {
  margin-bottom: 1rem;
}

.event-text .event-text-image {
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem;
  max-width: 400px;
}
@media all and (max-width: 767px) {
  .event-text .event-text-image {
    max-width: 100%;
    margin-left: 0;
    float: none;
  }
}
.event-text p img {
  width: auto;
}

.masonry-grid-items {
  width: 100%;
  position: relative;
}
.masonry-grid-items .item-blog {
  position: absolute;
  display: block;
  width: calc(25% - 15px);
  z-index: 1;
  margin-right: 15px;
  margin-bottom: 15px;
}
.masonry-grid-items .item-blog.item-blog__double, .masonry-grid-items .item-blog.popular-post {
  width: calc(50% - 15px);
}
.masonry-grid-items .item-blog .inner {
  position: relative;
  width: 100%;
  height: 100%;
}
@media all and (max-width: 1200px) {
  .masonry-grid-items .item-blog {
    width: calc(50% - 15px);
  }
  .masonry-grid-items .item-blog.item-blog__double, .masonry-grid-items .item-blog.popular-post {
    width: calc(100% - 15px);
  }
}
@media all and (max-width: 991px) {
  .masonry-grid-items .item-blog {
    width: calc(100% - 15px);
  }
  .masonry-grid-items .item-blog.item-blog__double, .masonry-grid-items .item-blog.popular-post {
    width: calc(100% - 15px);
  }
}

.masonry-grid-items-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.book-a-meeting {
  margin-bottom: 3rem;
}
.book-a-meeting .inner {
  background-color: #86ceed;
  background-image: linear-gradient(90deg, #1690c0 0%, #276ea3 100%);
  padding: 2rem 3rem;
  border-radius: 0.5rem;
}
@media all and (min-width: 992px) {
  .book-a-meeting .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.book-a-meeting .inner p {
  padding: 0;
  font-size: 2rem;
  font-weight: 500;
  color: #FFF;
  line-height: 1;
}
@media all and (min-width: 992px) {
  .book-a-meeting .inner p {
    margin: 0;
  }
}
.book-a-meeting .inner .btn.btn-book-meeting {
  background-color: #FFF;
  display: block;
  padding: 15px;
}
.book-a-meeting .inner .btn.btn-book-meeting:hover {
  background-color: #192259;
  color: #FFF;
}
.book-a-meeting .inner__right {
  flex-basis: 30%;
}

@media all and (min-width: 992px) {
  .feedback-button {
    position: fixed;
    right: 0;
    top: var(--feedback-button-top);
    z-index: 1000;
    transform: rotate(-90deg);
    height: 40px;
    right: -80px;
    transform-origin: 0% 0%;
    border: 0;
    padding-left: 25px;
    padding-right: 25px;
    background-color: var(--feedback-button-background-color);
    color: var(--feedback-button-foreground-color);
  }
  .feedback-button:hover {
    background-color: var(--feedback-button-hover-background-color);
    color: var(--feedback-button-hover-foreground-color);
    height: 50px;
    right: -70px;
  }
  .feedback-button span {
    position: relative;
  }
}
@media all and (max-width: 991px) {
  .feedback-button {
    display: none;
  }
}
.next-previous-links {
  border-top: 1px solid #D6D9DA;
  padding-top: calc(var(--base-spacing) / 2);
  margin-top: var(--base-spacing);
}
.next-previous-links--link {
  display: block;
  font-weight: 500;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1D8DBD;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.customer-logo-grid {
  width: 100%;
  position: relative;
  padding: 6rem 2rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.customer-logo-grid .image {
  flex: 0 0 calc(10% - 30px);
  border: 1px solid #EFEFEF;
  display: flex;
  background-color: #FFF;
  align-items: center;
  justify-content: center;
  min-height: 36px;
}
@media all and (min-width: 1340px) {
  .customer-logo-grid .image {
    flex: 0 0 calc(9% - 30px);
  }
}
@media all and (min-width: 1200px) and (max-width: 1339px) {
  .customer-logo-grid .image {
    flex: 0 0 calc(14% - 30px);
  }
}
@media all and (min-width: 992px) and (max-width: 1199px) {
  .customer-logo-grid .image {
    flex: 0 0 calc(15% - 30px);
  }
}
@media all and (max-width: 991px) {
  .customer-logo-grid .image {
    flex: 0 0 calc(20% - 30px);
  }
}
.customer-logo-grid .image img {
  object-fit: cover;
}

:root {
  --box-padding: 15px;
  --spacing-around: 45px;
  --image-width: 200px;
  --border-radius: 20px;
}

/* Timeline Container */
.timeline {
  padding: 44px 2rem 0 2rem;
  background-color: #f1f2f2;
  overflow: hidden;
  border-radius: var(--border-radius);
  /* Card container */
  /* Global ::before */
  /* Setting the border of top, bottom, left */
  /* Removing the border if it is the first card */
  /* Removing the border if it is the last card  and it's odd */
  /* Removing the border if it is the last card  and it's even */
  /* Information about the timeline */
  /* Title of the card */
  /* Timeline dot  */
  /* text right if the card is even  */
}
.timeline .timeline-card {
  position: relative;
  max-width: 100%;
}
.timeline .timeline-card {
  padding: var(--spacing-around) var(--spacing-around) var(--spacing-around) var(--spacing-around);
  position: relative;
}
.timeline .timeline-card > span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  top: -10px;
  background-color: #192259;
  color: #FFF;
  font-weight: 500;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 1.6rem;
}
.timeline .timeline-card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #192259;
}
.timeline .timeline-card::before {
  left: calc(50% - 1px);
  top: -44px;
  bottom: -4.5px;
  border-width: 4px;
  border-radius: 0;
  width: 4px;
}
.timeline .timeline-card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}
.timeline .timeline-card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}
.timeline .timeline-card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}
.timeline .info {
  display: flex;
  flex-direction: column;
  background: #FFF;
  color: #000;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 80%;
}
@media all and (max-width: 991px) {
  .timeline .info {
    width: 100%;
  }
}
.timeline .title {
  background-color: #0090C0;
  color: #FFF;
  font-size: 1.8rem;
  position: relative;
  padding: 10px var(--box-padding);
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 767px) {
  .timeline .title {
    flex-direction: column !important;
  }
  .timeline .title span {
    margin: 0 !important;
    font-size: 1.6rem;
  }
}
.timeline .title span {
  font-weight: 400;
}
.timeline .title span:first-child {
  margin-left: 0;
  margin-right: 15px;
  font-weight: 600;
}
.timeline .title::before {
  content: "";
  display: none;
}
.timeline .timeline-card:nth-child(odd) > .info::before {
  left: calc(50% + 75px);
}
@media all and (max-width: 991px) {
  .timeline .timeline-card:nth-child(odd) > .info::before {
    left: 50%;
  }
}
.timeline .timeline-card:nth-child(even) > .info::before {
  right: calc(50% + 70px);
}
@media all and (max-width: 991px) {
  .timeline .timeline-card:nth-child(even) > .info::before {
    right: 50%;
  }
}
.timeline .copy {
  background-color: #FFF;
  padding: 20px var(--box-padding);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .timeline .copy {
    flex-direction: column !important;
    padding: 0 0 20px 0px;
  }
}
.timeline .copy--text {
  padding: 0 var(--box-padding);
}
@media all and (max-width: 767px) {
  .timeline .copy--text {
    padding-top: 15px;
  }
}
.timeline .copy--image {
  max-width: var(--image-width);
  margin-right: 15px;
  margin-left: 0;
}
@media all and (max-width: 767px) {
  .timeline .copy--image {
    max-width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
}
.timeline .timeline-card:nth-child(even) {
  display: flex;
  justify-content: flex-end;
}
.timeline .timeline-card:nth-child(even) > .info > .copy {
  flex-direction: row-reverse;
}
.timeline .timeline-card:nth-child(even) > .info > .copy .copy--image {
  margin-right: 0;
  margin-left: 15px;
}

.repeatable.tabbed.tab-basic .tabs:after {
  height: 1px;
}

.tab-gallery {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media all and (max-width: 767px) {
  .tab-gallery {
    gap: 0;
  }
}
.tab-gallery__item {
  flex-basis: calc(25% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #D6D9DA;
}
@media all and (max-width: 992px) {
  .tab-gallery__item {
    flex-basis: calc(50% - 10px);
  }
}
@media all and (max-width: 767px) {
  .tab-gallery__item {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
}

.slick-slider .slick-prev, .slick-slider .slick-next {
  opacity: 0.75;
}
.slick-slider .slick-prev:hover, .slick-slider .slick-next:hover {
  opacity: 1 !important;
}

.base-bg-solid .slick-slider .slick-prev, .base-bg-solid .slick-slider .slick-next {
  color: rgba(var(--base-heading), 0.75);
  background-color: rgba(255, 255, 255, 0.5);
}
.base-bg-solid .slick-slider .slick-prev:hover, .base-bg-solid .slick-slider .slick-next:hover {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.base-bg-solid .slick-slider .slick-prev:hover, .base-bg-solid .slick-slider .slick-next:hover {
  color: rgba(var(--base-heading), 1) !important;
}

.logo-slick-slider .slick-slider .slick-prev, .logo-slick-slider .slick-slider .slick-next {
  top: 100% !important;
}
.logo-slick-slider .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev {
  left: calc(var(--base-half-spacing) / 2);
}
.logo-slick-slider .row.slides.slick-slider:not(.listing_no-spacing) .slick-next {
  right: calc(var(--base-half-spacing) / 2);
}
@media all and (max-width: 1199px) {
  .logo-slick-slider .slick-slider {
    padding-bottom: 30px;
  }
}

.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-autoplay-toggle-button {
  bottom: -15px;
}
.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev, .icon-listing .slick-slider .slick-next {
  top: auto;
  bottom: -15px;
  transform: none;
  background-color: transparent;
}
.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev:hover, .icon-listing .slick-slider .slick-next:hover {
  background-color: transparent;
}
.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev {
  left: 0;
}
.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-next {
  right: 0;
}
.icon-listing .row.slides.slick-slider:not(.listing_no-spacing) .slick-autoplay-toggle-button {
  left: 50%;
  transform: translateX(-50%);
}

.termsfeed-com---nb-simple {
  right: auto !important;
}
@media all and (min-width: 1120px) {
  .termsfeed-com---nb-simple {
    max-width: 30% !important;
  }
}

@media all and (max-width: 991px) {
  .termsfeed-com---nb-simple {
    max-width: 100% !important;
  }
}
.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: #FFF !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}
@media all and (min-width: 992px) {
  .termsfeed-com---palette-light.termsfeed-com---nb {
    border-top-right-radius: 0.5rem;
  }
}
.termsfeed-com---palette-light .cc-nb-okagree, .termsfeed-com---palette-light .cc-nb-reject, .termsfeed-com---palette-light .cc-cp-foot-save {
  background-color: #0090C0 !important;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}
.termsfeed-com---palette-light .cc-nb-okagree:hover, .termsfeed-com---palette-light .cc-nb-reject:hover, .termsfeed-com---palette-light .cc-cp-foot-save:hover {
  background-color: #192259 !important;
}
.termsfeed-com---palette-light .cc-nb-changep {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}
.termsfeed-com---palette-light .cc-cp-body-content-entry-title, .termsfeed-com---palette-light .cc-pc-head-title-headline {
  color: #192259 !important;
}
.termsfeed-com---palette-light .cc-pc-head-title-text {
  color: #0090C0 !important;
}

.termsfeed-com---pc-dialog {
  font-family: "Ubuntu", sans-serif !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs {
  font-family: "Ubuntu", sans-serif !important;
}

.cc-nb-text {
  opacity: 0;
  transition: opacity 0.25s;
}
.cc-nb-text.in {
  opacity: 1;
}

.cc-nb-reject {
  display: none !important;
}

.inline-image-wrapper {
  display: inline-block;
}
.inline-image-wrapper.alignment-full {
  width: 100%;
}
.inline-image-wrapper.alignment-full .inline-image img {
  width: 100% !important;
}
.inline-image-wrapper .inline-image {
  display: block;
}
@media all and (max-width: 767px) {
  .inline-image-wrapper .inline-image {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .inline-image-wrapper .inline-image img {
    width: 100% !important;
  }
}

.component.usn_cmp_splitcomponent .item.testimonials-split .inner {
  padding: 0 4rem;
  display: flex;
  justify-content: center;
}
.component.usn_cmp_splitcomponent .item.testimonials-split .inner .info {
  padding: 5rem;
  background-color: rgba(0, 144, 192, 0.1);
  position: relative;
  border-radius: 0.5rem;
}
.component.usn_cmp_splitcomponent .item.testimonials-split .inner .info:before {
  content: " ";
  background-image: var(--quote66);
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 2rem;
  left: 2rem;
  opacity: 0;
}
.component.usn_cmp_splitcomponent .item.testimonials-split .inner .info:after {
  content: " ";
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  background-image: var(--quote99);
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  opacity: 0;
}
@media all and (max-width: 991px) {
  .component.usn_cmp_splitcomponent .item.testimonials-split .row.slides.slick-slider:not(.listing_no-spacing) .slick-prev {
    left: 15px;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .row.slides.slick-slider:not(.listing_no-spacing) .slick-next {
    right: 15px;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .inner {
    padding: 0;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .inner .info {
    padding: 2rem 3.5rem;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .inner .info .text .quote-wrapper .quote {
    font-size: 1rem;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .inner .info .text .quote-wrapper .quote-source {
    font-size: 20px;
  }
  .component.usn_cmp_splitcomponent .item.testimonials-split .inner .info .text .quote-wrapper .quote-source .quote-source-photo {
    max-width: calc(var(--inline-quote-img-max-width) / 1.5);
  }
}

a:focus-visible, .btn:focus-visible, button:focus-visible, .item .image a:focus-visible img, .item .image a:focus-visible picture, .item .image a:focus-visible .overlayicon, a:focus-visible .image::before, .listing_no-spacing .item a:focus-visible .image, .listing_no-spacing .item a:focus-visible .image img, .listing_no-spacing .item a:focus-visible .image picture, .listing_no-spacing .item a:focus-visible .image .overlayicon, .usn_cmp_windows .item.item_text-boxed a:focus-visible .image, .usn_cmp_windows .item.item_text-boxed a:focus-visible .image img, .usn_cmp_windows .item.item_text-boxed a:focus-visible .image picture, header:focus-visible, main:focus-visible > section:first-of-type, section:focus-visible, footer:focus-visible, header#site-header nav.shopping-cart button.open-cart:focus-visible, header#site-header nav.shopping-cart .ec-minicart:focus-visible {
  outline: none !important;
}

.form-control:focus-visible, .umbraco-forms-field input:focus-visible, .umbraco-forms-field.longanswer textarea:focus-visible, .umbraco-forms-field.dropdown select:focus-visible {
  outline: none !important;
}

@media screen and (min-width: 992px) {
  html:not(.reveal-out).menu-at-top body.transparent-header-lg header#site-header,
  html:not(.reveal-out).reached-top body.transparent-header-lg header#site-header {
    top: 30px;
  }
  body.transparent-header-lg:not(.full-page):not(.no-header):not(.header-11-lg):not(.header-12-lg):not(.header-15-lg):not(.header-16-lg):not(.remove-top-content-margin) header#site-header + #site-content > .component:first-of-type:not(.usn_cmp_banner) {
    margin-top: calc(var(--large-header) + 30px);
  }
  body.transparent-header-lg:not(.full-page):not(.no-header):not(.header-11-lg):not(.header-12-lg):not(.header-15-lg):not(.header-16-lg):not(.remove-top-content-margin) header#site-header + #site-content > .component:first-of-type:not(.usn_cmp_banner) {
    margin-top: var(--large-header);
  }
  body.remove-top-content-margin header#site-header + #site-content > .component:first-of-type:not(.usn_cmp_banner) {
    margin-top: 30px !important;
  }
}
body.transparent-header-lg header#site-header:hover {
  background: rgba(var(--header-bg-color1), var(--headerTransparency));
}

body.transparent-header-lg header#site-header:hover:before,
html:not(.reached-top).nav-up body.transparent-header-lg header#site-header:before,
html:not(.reached-top).nav-down body.transparent-header-lg header#site-header:before {
  display: none;
}

body.transparent-header-lg header#site-header:before {
  pointer-events: none;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0) 100%);
  z-index: -1;
}

/*@media all and (max-width: $mobileBreakpointMax) {
    html.touch.nav-up {
        header#site-header {
            top: 30px
        }
    }
}*/
.usn_cmp_splitcomponent .heading + .secondary-heading,
.usn_cmp_splitcomponent .secondary-heading + .text,
.usn_cmp_splitcomponent .heading + .text {
  margin: var(--base-spacing) 0 0 !important;
}

.usn_cmp_text .info .text .image.image-in-text {
  text-align: center;
  width: 100%;
  margin-bottom: 1.5rem;
}
@media all and (min-width: 992px) {
  .usn_cmp_text .info .text .image.image-in-text {
    float: left;
    margin-right: 1.5rem;
    width: auto;
  }
  .usn_cmp_text .info .text .image.image-in-text img {
    max-width: 450px;
  }
}
.usn_cmp_text .info .text .image.image-in-text.float-right {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}
.usn_cmp_text .info .text img {
  width: auto;
}
.usn_cmp_text .info .text ul {
  list-style: none;
  margin-left: 0;
}
.usn_cmp_text .info .text ul li {
  list-style: none;
  position: relative;
  padding-left: 26px;
  margin-bottom: 20px;
}
.usn_cmp_text .info .text ul li:last-child {
  margin-bottom: 0;
}
.usn_cmp_text .info .text ul li > h3, .usn_cmp_text .info .text ul li > h4, .usn_cmp_text .info .text ul li > h5 {
  margin-bottom: 0;
}
.usn_cmp_text .info .text ul li > p {
  margin-top: 0;
}
.usn_cmp_text .info .text ul li:before {
  background-color: #22CAE6;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: " ";
  position: absolute;
  left: 0;
  top: 8px;
}
.usn_cmp_text .info .text table.table {
  border: 0;
}
.usn_cmp_text .info .text table.table thead tr th {
  border-right: 0;
  border-top: 0;
  background-color: #FFF;
  border-bottom-width: 2px;
  border-bottom-color: #D6D9DA;
  padding: 0 0.75rem 11px 0.75rem;
}
.usn_cmp_text .info .text table.table tbody tr {
  /*&:nth-child(even) {
      td {
          background-color: lighten($darkBlue, 77%) !important;
      }
  }*/
}
.usn_cmp_text .info .text table.table tbody tr th {
  border-right: 0;
  border-bottom-color: #D6D9DA;
  border-top: 0;
}
.usn_cmp_text .info .text table.table tbody tr td {
  border-right: 0;
  border-top: 0;
  border-bottom-color: #D6D9DA;
  padding: 11px 0.75rem;
}

@media (min-width: 1200px) {
  .component.usn_cmp_splitcomponent .container-fluid .item_block.usn_cmp_text .info {
    max-width: 700px;
  }
  .component.usn_cmp_gallery .component-inner .component-main.listing_gallery.slick-slider .slick-autoplay-toggle-button {
    bottom: -15px;
  }
}
@media (max-width: 1599px) {
  .component.usn_cmp_splitcomponent .component-inner .component-main .item.usn_cmp_gallery .listing_gallery .image.text-left, .component.usn_cmp_splitcomponent .component-inner .component-main .item.usn_cmp_gallery .listing_gallery .image.text-right {
    text-align: center !important;
  }
  .component.usn_cmp_splitcomponent .component-inner .component-main .item.usn_cmp_gallery .listing_gallery .slick-slider .slick-autoplay-toggle-button {
    bottom: -15px;
  }
}
@media all and (min-width: 768px) and (max-width: 1599px) {
  .component.usn_cmp_splitcomponent .component-inner .component-main .item.usn_cmp_text .info.text-left, .component.usn_cmp_splitcomponent .component-inner .component-main .item.usn_cmp_text .info.text-right {
    padding: 0 2rem;
  }
}
.content.component.usn_cmp_ctastrip {
  padding: 50px 0;
}
@media (min-width: 1200px) {
  .content.component.usn_cmp_ctastrip .text-left .link, .content.component.usn_cmp_ctastrip .text-right .link {
    max-width: 20% !important;
    flex: 0 0 20% !important;
  }
  .content.component.usn_cmp_ctastrip .text-right .info, .content.component.usn_cmp_ctastrip .text-left .info {
    max-width: 80% !important;
    flex: 0 0 80% !important;
  }
}

.bordered-listing .listing .item .inner {
  border: 1px solid rgba(var(--c1-bg-color1), 1);
  overflow: hidden;
  border-radius: 0.5rem;
}

.usn_cmp_accordiontab nav.tabs ul.nav li.tab a.nav-item.nav-link {
  border: 0;
  font-weight: 500;
  color: #192259;
}
.usn_cmp_accordiontab nav.tabs ul.nav li.tab a.nav-item.nav-link:hover {
  color: #22CAE6;
}
.usn_cmp_accordiontab nav.tabs ul.nav li.tab a.nav-item.nav-link.active {
  color: #22CAE6;
}
.usn_cmp_accordiontab nav.tabs ul.nav li.tab a.nav-item.nav-link.active:after {
  background: #22CAE6;
}
.usn_cmp_accordiontab nav.tabs ul.nav li.tab a.nav-item.nav-link:not(.active) {
  background: transparent;
}
.usn_cmp_accordiontab .tab-content {
  border: none !important;
}
nav.sub {
  border-top: 0;
}
nav.sub p.heading {
  border-bottom: 1px solid #192259;
  padding-top: 10px;
}
nav.sub ul li {
  border-bottom: 1px solid #D6D9DA;
}
nav.sub ul li.active > span a {
  color: #192259 !important;
  opacity: 1 !important;
}
nav.sub ul li:last-child {
  border-bottom: 0;
}
nav.sub ul li span {
  font-weight: 500;
  padding: calc(var(--base-spacing) / 3) 0;
  position: relative;
}
nav.sub ul li span a:hover {
  color: #192259 !important;
}

.left-col .sub ul li.active > span:after {
  opacity: 1;
  right: 0;
}
.left-col .sub ul li span:after {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Ionicons";
  content: "\f3d1";
  opacity: 0;
  transition: all 0.25s;
}
.left-col .sub ul li span:hover:after {
  opacity: 1;
  right: 0;
}
.left-col .sub ul li ul {
  padding-bottom: 1rem;
}
.left-col .sub ul li ul li {
  padding: 0;
  border-bottom: 0;
}
.left-col .sub ul li ul li span {
  padding: 0.5rem 0;
  font-weight: normal;
}
.left-col .sub ul li ul li span a:before {
  top: 50%;
  transform: translateY(-50%);
}
.left-col .sub ul li ul li span:after {
  display: none;
}
.left-col .sub ul li ul li.active span {
  font-weight: 500;
}

.right-col .sub ul li a:before {
  display: none;
}
.right-col .sub ul li.active > span:after {
  opacity: 1;
}
.right-col .sub ul li span {
  position: relative;
  width: 100%;
  display: block;
}
.right-col .sub ul li span:after {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Ionicons";
  content: "\f3d1";
  opacity: 0;
  transition: all 0.25s;
}
.right-col .sub ul li span:hover:after {
  opacity: 1;
  right: 0;
}

header#site-header .site-search-form .form-control {
  border: 0 !important;
  border-bottom-left-radius: var(--btn-border-radius);
  border-top-left-radius: var(--btn-border-radius);
}

.content .site-search-form {
  padding: var(--base-spacing);
  background-color: rgba(var(--c1-bg-color1), 1);
}
.content .site-search-form .form-control {
  border-radius: 0 !important;
}
.content .site-search-form .btn-search {
  border-radius: 0 !important;
  top: 1px;
  right: 1px;
}
.content .site-search-form .btn-search span {
  border-radius: 0 !important;
}
.content .listing_search-results .item {
  margin-bottom: calc(var(--base-spacing) / 2);
}
.content .listing_search-results .item .inner {
  padding: var(--base-spacing);
  background-color: #FFF;
  border: 1px solid #D6D9DA;
  border-radius: 0 !important;
  transition: background-color 0.25s;
}
.content .listing_search-results .item .inner:hover {
  background-color: rgba(var(--c1-bg-color1), 0.4);
}
.content .listing_search-results .item .inner .info .url {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0;
}

/*@media all and (min-width: 992px) {
    header#site-header .site-search0firn.open-search form {
            width: 100vw;
            max-width: calc(100vw - 300px);
    }
}   

*/
.site-search-form {
  transition: all 0.25s;
  opacity: 0;
  width: calc(100% - 230px);
  right: 0;
  position: absolute;
  top: -10px;
  padding: 1.25rem 1rem;
  z-index: 605;
  pointer-events: none;
}
.site-search-form.open-search {
  opacity: 1;
  pointer-events: all;
  top: 0;
}
.site-search-form form {
  margin: 0;
  padding: 0;
}
.site-search-form form fieldset {
  display: flex;
  margin: 0;
  position: relative;
}
.site-search-form form fieldset > div {
  display: flex;
  width: 100%;
  position: relative;
}
.site-search-form form fieldset > div .btn {
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: var(--base-border-width);
  right: var(--base-border-width);
  top: 0;
  right: 0;
  font-size: 25px;
  text-align: center;
}
.site-search-form form fieldset > div .btn > span {
  position: absolute;
}
.site-search-form form fieldset .form-group {
  flex: 1 0 auto;
  margin: 0;
  padding-right: 25px;
}
.site-search-form .close-search {
  content: "Open";
  width: 40px;
  height: 50px;
  padding: 0;
  display: block;
  z-index: 3;
  font-size: 25px;
  cursor: pointer;
  text-align: center;
  color: rgba(var(--main-navigation-link), 1);
  background: none;
  border: 0 none;
  margin-left: 10px;
}
.site-search-form .close-search em {
  display: none;
}

.site-search-form.open-search {
  opacity: 1;
}

.search-highlight {
  background-color: rgb(255, 255, 0);
  color: #000;
}

.content.component:not(.usn_cmp_splitcomponent) .umbraco-forms.form input.text, .content.component:not(.usn_cmp_splitcomponent) .umbraco-forms.form textarea, .content.component:not(.usn_cmp_splitcomponent) .umbraco-forms.form select {
  max-width: 100% !important;
}
.content.component:not(.usn_cmp_splitcomponent) .umbraco-forms.form select {
  border-radius: 0.25rem;
}

@media all and (min-width: 992px) {
  .umbraco-forms.form input.text, .umbraco-forms.form textarea {
    padding: 10px;
  }
}
.umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist {
  padding-left: 0;
  width: 100%;
}
@media all and (min-width: 992px) {
  .umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist {
    width: 50%;
  }
}
.umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist input[type=checkbox] {
  float: left;
  top: 8px;
}
.umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist label {
  float: none;
  clear: none;
  padding-left: 10px;
  margin-bottom: 0.5rem;
}

.listing_data-list .item_data-list .text p {
  margin: 0;
}
.listing_data-list .item_data-list .text p a {
  color: rgba(var(--base-link), 1);
}
.listing_data-list .item_data-list .text p a:hover {
  color: #192259;
}
.listing_data-list .item_data-list .col-12:not(.item) {
  margin-bottom: 0;
}
.listing_data-list .column-titles {
  padding-bottom: 15px;
  padding-top: 7px;
}
.listing_data-list .column-titles:after {
  border-bottom: 2px solid #d6d9da;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  content: " ";
  height: 2px;
}
.listing_data-list .column-titles .column-title {
  font-weight: 500;
  margin-bottom: 15px;
  color: rgba(var(--base-heading), 1);
}

.listing.listing_data-list .item.items-1:before {
  background-color: #d6d9da;
  bottom: 0 !important;
  content: "";
  position: absolute;
  right: 15px;
  left: 15px;
  height: var(--base-border-width);
  background-color: #d6d9da !important;
}

.listing.listing_data-list .item {
  padding: 11px 0.75rem 12px 0.75rem;
  margin: 0 !important;
}

.listing.listing_data-list .item a {
  font-weight: 500;
}

.listing.listing_data-list .item .download-link {
  text-align: right;
}

@media all and (max-width: 767px) {
  .listing.listing_data-list .item .download-link {
    display: none;
  }
}
.show-items-filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.show-items-filter label span {
  display: inline-block;
  margin-right: 10px;
}
.show-items-filter label select {
  padding: 8px 10px;
  appearance: auto;
  border-radius: 0 !important;
}
.show-items-filter.move-filter-down {
  position: absolute;
  top: 0;
  z-index: 1;
}

@media all and (min-width: 992px) {
  nav.sub {
    padding-top: 0;
  }
}

@media all and (min-width: 992px) {
  .repeatable.tabbed__vertical {
    display: flex;
  }
}
.repeatable.tabbed__vertical nav.tabs {
  flex-basis: 25%;
}
.repeatable.tabbed__vertical nav.tabs ul.nav li.tab {
  display: block;
  margin: 0 1rem 0 0;
  width: 100%;
}
.repeatable.tabbed__vertical nav.tabs ul.nav li.tab:first-child a.nav-item.nav-button-link {
  padding-top: 0;
}
.repeatable.tabbed__vertical nav.tabs ul.nav li.tab a.nav-item.nav-button-link {
  padding: 1rem 0;
}
.repeatable.tabbed__vertical .tab-content {
  flex-basis: 100%;
  padding-top: 0;
}

.accordion.tab-basic.accordion__tabbedVertical .tab a.nav-link.nav-button-link {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  font-weight: 500;
  border-bottom: 2px solid #22CAE6;
  color: #22CAE6;
}
.accordion.tab-basic.accordion__tabbedVertical .tab a.nav-link.nav-button-link.collapsed {
  color: rgba(var(--base-heading), 1);
  border-radius: 0;
  border-bottom: 2px solid transparent;
}
.accordion.tab-basic.accordion__tabbedVertical .tab a.nav-link:hover {
  color: #22CAE6 !important;
  background-color: transparent !important;
}
.accordion.tab-basic.accordion__tabbedVertical .info.repeatable-content {
  border: 0;
}
.accordion.tab-basic.accordion__tabbedVertical .info.repeatable-content .text {
  padding-left: 0;
  padding-right: 0;
}

.repeatable.tabbed.tab-basic .tabs {
  position: relative;
}
.repeatable.tabbed.tab-basic .tabs:after {
  position: absolute;
  content: " ";
  border-bottom: 1px solid #000;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.repeatable.tabbed.tab-basic .tabs .nav .tab {
  margin-right: 0;
}
.repeatable.tabbed.tab-basic .tabs .nav .tab .nav-item.nav-link {
  padding: 0 var(--base-half-spacing) 16px var(--base-half-spacing);
}
.repeatable.tabbed.tab-basic .tabs .nav .tab .nav-item.nav-link:not(.active):after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background: transparent;
  z-index: 2;
}
.repeatable.tabbed.tab-basic .tabs .nav .tab .nav-item.nav-link.active:after {
  height: 1px;
  z-index: 2;
}

@media all and (min-width: 992px) {
  .modal-content section.content {
    padding-bottom: 0;
    padding-top: 50px;
  }
  .modal-content section.content .component-introduction {
    padding: 0 var(--base-spacing);
  }
  .modal-content section.content .component-introduction .secondary-heading.c1-secondary-heading {
    color: rgba(var(--c1-heading), 1) !important;
    font-weight: 500;
  }
  .modal-content section.content .inner {
    padding-top: 0;
  }
  .modal-content section.content .umbraco-forms-form .umbraco-forms-navigation {
    padding-top: 0;
  }
  .modal-content section.content .umbraco-forms-field.recaptcha .umbraco-forms-label {
    display: none;
  }
}
.content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-img {
  height: 100%;
}
.content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-img .image {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media all and (max-width: 991px) {
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-img .image {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media all and (min-width: 992px) {
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .info.info--hhNotAngled {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .container, .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .container-lg, .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .container-md, .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .container-sm {
    max-width: var(--header-max-width) !important;
  }
}
@media all and (max-width: 991px) {
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled {
    height: 100%;
    min-height: 100vh;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner {
    position: relative;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .container {
    height: 100%;
    min-height: 100vh;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-item-row {
    height: 100%;
    flex-wrap: nowrap;
    flex-direction: column !important;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-item-row .info {
    padding: 0 var(--base-half-spacing);
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-item-row .info .d-table-cell {
    vertical-align: top !important;
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-item-row .inner-img {
    height: 500px;
    margin-bottom: var(--base-spacing);
    padding-top: var(--base-spacing);
  }
  .content.component.usn_cmp_banner .item.item_short-banner.half-n-half.not-angled .inner-item-row .inner-img .image {
    display: flex;
    position: relative;
    margin-bottom: var(--base-spacing);
    align-items: center;
  }
}

.listing-pods .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle), .listing:not(.listing_window-mosaic) .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle) {
  margin-top: var(--base-half-spacing);
}
.listing-pods .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).center-image, .listing:not(.listing_window-mosaic) .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).center-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.listing-pods .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).remove-border-radius, .listing:not(.listing_window-mosaic) .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).remove-border-radius {
  border-radius: 0 !important;
}
.listing-pods .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).remove-border-radius img, .listing:not(.listing_window-mosaic) .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .image:not(.rounded-circle).remove-border-radius img {
  border-radius: 0 !important;
}
.listing-pods .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .info, .listing:not(.listing_window-mosaic) .usn_pod_textimage:not(.item_text-below):not(.item_text-above) .inner .info {
  margin-top: var(--base-half-spacing);
}
.listing-pods .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle), .listing-pods .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle), .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle), .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle) {
  padding-top: var(--base-half-spacing);
}
.listing-pods .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).center-image, .listing-pods .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).center-image, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).center-image, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).center-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.listing-pods .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).remove-border-radius, .listing-pods .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).remove-border-radius, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).remove-border-radius, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).remove-border-radius {
  border-radius: 0 !important;
}
.listing-pods .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).remove-border-radius img, .listing-pods .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).remove-border-radius img, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-below .inner .image:not(.rounded-circle).remove-border-radius img, .listing:not(.listing_window-mosaic) .usn_pod_textimage.item_text-above .inner .image:not(.rounded-circle).remove-border-radius img {
  border-radius: 0 !important;
}
.listing-pods .info-heading-image, .listing:not(.listing_window-mosaic) .info-heading-image {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: var(--base-spacing);
}
.listing-pods .info-heading-image .heading-image.image, .listing:not(.listing_window-mosaic) .info-heading-image .heading-image.image {
  padding: 0 !important;
  margin-right: var(--base-spacing);
  max-width: 80px;
}

.item-quote blockquote:before {
  content: " ";
  background-image: url(../images/cerillion/icons/quote.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 27px;
  transform: none;
  top: 0;
  left: 0;
  position: absolute;
}
.item-quote .quote {
  font-weight: 500;
}
.item-quote .meta {
  display: flex;
  flex-direction: row;
}
.item-quote .meta span.quote-profile {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  margin-right: 1rem;
}
.item-quote .meta .quote-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.item-quote .meta .quote-info .person {
  color: #22CAE6;
  font-weight: 700;
  font-size: var(--h4-typography_font-size_xl);
  margin: 0;
}
.item-quote .meta .quote-info .position-company {
  color: #192259;
  font-weight: 500;
  font-size: var(--body-typography_font-size_xl);
  margin: 0;
}

.content.component.usn_cmp_pods.base-bg .item.usn_pod_video .inner {
  background-color: rgba(var(--c1-bg-color1), 1);
  padding: var(--base-spacing);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.content.component.usn_cmp_pods.base-bg .item.usn_pod_video .inner .heading {
  font-size: 18px;
}
.content.component.usn_cmp_pods.base-bg .item.usn_pod_video .inner .image {
  margin-top: calc(var(--base-spacing) / 2);
}
.content.component.usn_cmp_pods:not(.base-bg) .item.usn_pod_video .inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.content.component.usn_cmp_pods:not(.base-bg) .item.usn_pod_video .inner .heading {
  font-size: 18px;
}
.content.component.usn_cmp_pods:not(.base-bg) .item.usn_pod_video .inner .secondary-heading {
  color: rgba(var(--c1-heading), 1) !important;
  font-size: 16px;
  font-weight: 500;
}
.content.component.usn_cmp_pods:not(.base-bg) .item.usn_pod_video .inner .image {
  margin-top: calc(var(--base-spacing) / 2);
}

:root {
  --component-max-width: 1400px;
  --mobileMenuBreakpoint: 992px;
}

/* START -- Fix header logo size */
header#site-header #logo picture,
header#site-header #logo img {
  max-width: 174px;
  min-height: 60px;
}

header#site-header #logo {
  display: flex;
  align-items: center;
}

/* END -- Fix header logo size */
/* START -- Fix footer logo size */
footer#site-footer .usn_pod_image img {
  max-width: 174px;
  height: auto;
}

footer#site-footer .usn_pod_image {
  display: flex;
  align-items: flex-start;
}

/* END -- Fix footer logo size */
/* START -- Add semi-transparency to header when scrolled */
html.nav-down:not(.reached-top) body.transparent-header-lg header#site-header,
html.nav-up:not(.reached-top) body.transparent-header-lg header#site-header,
html.nav-down:not(.reached-top) body.transparent-header-lg header#site-header.header-bg-solid,
html.nav-up:not(.reached-top) body.transparent-header-lg header#site-header.header-bg-solid {
  background: rgba(var(--header-bg-color1), var(--headerTransparency));
}

/* END -- Add semi-transparency to header when scrolled */
/* START -- Remove X padding from pods */
.item.item_text-below.remove-x-pad .inner .info {
  padding-left: 0;
  padding-right: 0;
}

/* END -- Remove X padding from pods */
body.main-navigation-underline-link-hover header#site-header nav.main a:hover,
body.main-navigation-underline-link-hover header#site-header nav.main a:focus,
body.main-navigation-underline-link-hover header#site-header nav.main a:active {
  text-decoration: none;
}

#site-footer .footer-logo .image img {
  width: 100%;
}

@media screen and (min-width: 992px) {
  body.page-has-no-banner.transparent-header-lg header#site-header {
    background: rgba(var(--header-bg-color1), 1);
  }
}
.masonry-grid-items {
  opacity: 0;
  transition: opacity 0.25s;
}

.content.component.hide-intro-button .component-inner {
  /*@media all and (min-width: 992px) {
      .item.item_short-banner .row {
          height: 50vh !important;
      }
  }*/
}
.content.component.hide-intro-button .component-inner .component-introduction .info p.link {
  display: none !important;
}
.content.component.hide-intro-button .component-inner .embedded-buttons--wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: none;
}
@media all and (min-width: 992px) {
  .content.component.hide-intro-button .component-inner .embedded-buttons--wrapper {
    display: block;
  }
}
.content.component.hide-intro-button .component-inner .embedded-buttons {
  margin-top: calc(var(--base-spacing) * 1.5);
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: var(--header-max-width);
  margin-left: auto;
  margin-right: auto;
}
.content.component.hide-intro-button .component-inner .embedded-buttons .btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.content.component.hide-intro-button .component-inner .embedded-buttons .btn + .btn {
  margin-left: 0.5rem;
}
.content.component.hide-intro-button .component-inner .embedded-buttons .btn .icon {
  margin-left: 1rem;
}

.usn_cmp_banner.hide-intro-button .item.item_short-banner.half-n-half.not-angled .info.info--hhNotAngled {
  padding-top: 0 !important;
}

.usn_cmp_subpagelisting .listing:not(.listing_window-mosaic) .item.item_has-bg .image:not(.rounded-circle) {
  margin: 1px 1px 0 1px;
}
